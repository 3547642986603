import i18n from 'i18next';
import enLocale from 'locales/en.json';

i18n.init({
  fallbackLng: 'en',
  react: {
    wait: true,
  },
});

i18n.addResourceBundle('en', 'translation', enLocale, true, true);

export default i18n;
